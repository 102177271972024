import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import pattern from "../images/pattern.jpg"
import { LinkButtonSecondary } from "./button"

const CallToAction = ({ cta }) => (
  <StyledComp>
    <Background>
      <BackgroundImage
        image={cta.image ? cta.image.url : null}
      ></BackgroundImage>
      <BackgroundPattern image={pattern}></BackgroundPattern>
    </Background>
    <Wrapper>
      <Content>
        <h4>{cta.text}</h4>
        <LinkButtonSecondary to={cta.url}>{cta.callToAction}</LinkButtonSecondary>
      </Content>
    </Wrapper>
  </StyledComp>
)

const StyledComp = styled.div`
  position: relative;
  height: 20rem;
`

const Background = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const BackgroundImage = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  background-image: url(${props => props.image});
  background-size: cover;
  flex-basis: 100%;
  @media screen and (min-width: 769px) {
    flex-basis: 70%;
  }
`

const BackgroundPattern = styled.div`
  background: ${DesignTokens.colors.secondary[500]};
  background-image: url(${props => props.image});
  background-size: 200%;
  flex-basis: 0%;
  @media screen and (min-width: 769px) {
    flex-basis: 30%;
  }  
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  width: 80%;
  background: ${DesignTokens.colors.primary[500]};
  padding: ${DesignTokens.spacing[9]};
  margin: 0;
  h4 {
    color: ${DesignTokens.colors.white};
    font-size: ${DesignTokens.fontSizes[7]};
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  @media screen and (min-width: 769px) {
    width: 40%;
    margin-left: 40%;
  }
`

export default CallToAction
