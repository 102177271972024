import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Label from "../components/label"
import Heading from "../components/heading"
import pattern from "../images/pattern.jpg"
import {
  ButtonGroup,
  LinkButton,
  LinkButtonTertiary,
} from "../components/button"
import { ItemsList } from "../components/utils"
import CallToAction from "../components/callToAction"
import Container from "../components/container"
import Footer from "../components/footer"

const IndexPage = ({ data: { page, processing } }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Slider image={page.heroImage.url}>
        <Container>
          <Label as="h1">{page.title}</Label>
          <Heading
            as="h2"
            dangerouslySetInnerHTML={{ __html: page.subtitle }}
          />
          <ItemsList>
            {page.slider.slice(0, 1).map(content => (
              <li
                key={content.id}
                dangerouslySetInnerHTML={{ __html: content.text }}
              />
            ))}
          </ItemsList>
          <ButtonGroup>
            <LinkButton to="/carpenteria-formenti/">Scopri di più</LinkButton>
            <LinkButtonTertiary to="/contatti/">Contattaci</LinkButtonTertiary>
          </ButtonGroup>
        </Container>
      </Slider>
      <About>
        <AboutContainer>
          <img src={page.about[0].image.url} alt={page.about[0].image.alt} />
          <div>
            <Heading as="h3">{page.about[0].title}</Heading>
            <div
              dangerouslySetInnerHTML={{ __html: page.about[0].paragraph }}
            />
            <LinkButton to={page.about[0].url}>
              {page.about[0].callToAction}
            </LinkButton>
          </div>
        </AboutContainer>
      </About>
      <Processing>
        <Container>
          <Heading as="h3">{page.processingTitle}</Heading>
          <div dangerouslySetInnerHTML={{ __html: page.processingParagraph }} />
          <ItemsList>
            {processing.nodes.map(process => (
              <li key={process.id}>
                <Label color={DesignTokens.colors.tertiary[500]}>
                  Lavorazione {process.kind ? "interna" : "esterna"}
                </Label>
                <h4>{process.title}</h4>
                <img src={process.icon.url} alt={process.icon.alt} />
              </li>
            ))}
          </ItemsList>
        </Container>
      </Processing>
      <Solutions image={page.solutions[0].heroImage.url}>
        <Container>
          <Heading as="h3">{page.solutions[0].title}</Heading>
          <div
            dangerouslySetInnerHTML={{ __html: page.solutions[0].subtitle }}
          />
          <ItemsList>
            {page.solutions[0].solutions.map(solution => (
              <li key={solution.id}>
                <img src={solution.image.url} alt={solution.image.alt} />
                <h4>{solution.title}</h4>
              </li>
            ))}
          </ItemsList>
        </Container>
      </Solutions>
      <KeyNumbers>
        <Container>
          <ItemsList>
            {page.keyNumbers.map(number => (
              <li key={number.id}>
                <h4>{number.number}</h4>
                <div dangerouslySetInnerHTML={{ __html: number.legend }} />
              </li>
            ))}
          </ItemsList>
        </Container>
      </KeyNumbers>
      <CallToAction cta={page.callToAction} />
      <Footer />
    </Layout>
  )
}

const KeyNumbers = styled.section`
  @media screen and (min-width: 769px) {
    padding: ${DesignTokens.spacing[9]} 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    text-align: center;
    margin: 0;
    width: 50%;
    padding: ${DesignTokens.spacing[5]};
    h4 {
      font-size: ${DesignTokens.fontSizes[8]};
    }
    p {
      color: ${DesignTokens.colors.primary[500]};
    }
    @media screen and (min-width: 769px) {
      width: 20%;
    }
  }
`

const Solutions = styled.section`
  padding: ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.primary[500]};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  color: ${DesignTokens.colors.secondary[500]};
  @media screen and (min-width: 769px) {
    padding: 6rem ${DesignTokens.spacing[5]};
  }
  h3 {
    color: ${DesignTokens.colors.secondary[500]};
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  div > p {
    margin-bottom: ${DesignTokens.spacing[7]};
    @media screen and (min-width: 769px) {
      margin-bottom: ${DesignTokens.spacing[9]};
    }
  }
  img {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  ul {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    li {
      min-width: 400px;
      margin-right: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Processing = styled.section`
  padding: ${DesignTokens.spacing[5]};
  h3 {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  div > p {
    margin-bottom: ${DesignTokens.spacing[7]};
    @media screen and (min-width: 769px) {
      margin-bottom: ${DesignTokens.spacing[9]};
    }
  }
  h4 {
    font-size: ${DesignTokens.fontSizes[5]};
    color: ${DesignTokens.colors.primary[500]};
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  li {
    padding: ${DesignTokens.spacing[5]};
    margin: 0 4% 4% 0;
    width: 48%;
    border: 1px solid ${DesignTokens.colors.black};
    border-bottom: 6px solid ${DesignTokens.colors.primary[500]};
    img {
      height: 40%;
    }
    &:nth-child(even) {
      margin-right: 0;
    }
    @media screen and (min-width: 769px) {
      padding: ${DesignTokens.spacing[7]};
      width: calc(92% / 3);
      &:nth-child(even) {
        margin-right: 4%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

const About = styled.section`
  padding: ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.black};
  background-image: url(${pattern});
  background-size: 200%;
  img {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  h3 {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  p {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  @media screen and (min-width: 769px) {
    padding: 7rem ${DesignTokens.spacing[5]};
    background-size: 50%;
    img {
      max-width: 50%;
    }
  }
`

const AboutContainer = styled(Container)`
  @media screen and (min-width: 769px) {
    display: flex;
    align-items: center;
    background-size: 50%;
    flex-direction: row;
    img {
      margin-bottom: 0;
      margin-right: ${DesignTokens.spacing[9]};
    }
  }
`

const Slider = styled.div`
  padding: 6rem ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.black};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  color: ${DesignTokens.colors.white};
  @media screen and (min-width: 769px) {
    h1 {
      margin-bottom: 1rem;
    }
    h2 {
      margin-bottom: 1.5rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
    padding: 12rem ${DesignTokens.spacing[9]};
  }
`

export default IndexPage

export const query = graphql`
  query HomeQuery {
    page: datoCmsHomePage {
      title
      subtitle
      heroImage {
        url
      }
      slider {
        text
        id
      }
      about {
        callToAction
        image {
          url
          alt
        }
        paragraph
        title
        url
      }
      processingTitle
      processingParagraph
      solutions {
        title
        subtitle
        solutions {
          id
          title
          image {
            url
            alt
          }
        }
        heroImage {
          url
          alt
        }
      }
      keyNumbers {
        number
        id
        legend
        originalId
      }
      callToAction {
        text
        image {
          url
          alt
        }
        callToAction
        url
      }
    }
    processing: allDatoCmsProcessing(sort: { fields: position }) {
      nodes {
        icon {
          url
          alt
        }
        title
        kind
        id
      }
    }
  }
`
